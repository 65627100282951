import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../../layout"
import { Container, Title } from "../../uikit"
import Content from "../BlogPost/components/Content"

const Privacy = ({ location, pageContext }) => {
  const { locale } = useIntl()

  const currentNode = pageContext.nodes.find(
    node => node.node_locale.toLowerCase() === locale.toLowerCase()
  )

  if (!currentNode) {
    return null
  }

  return (
    <Layout location={location}>
      <Container>
        <Title>{currentNode.title}</Title>
        <Content json={currentNode.body.json} />
      </Container>
    </Layout>
  )
}

export default Privacy
